import { Dispatch, FC, SetStateAction } from 'react';

import { IComment } from '@lib/core/comments/types';
import { useUser } from '@lib/core/users/hooks';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { useDisclosure } from '@lib/tools/views/hooks/useDisclosure';

import noteIcon from '@components/web/src/assets/icons/comment/NoteIcon.svg';
import noteIconFill from '@components/web/src/assets/icons/comment/NoteIconFill.svg';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/Comment/styles';
import CommentModal from '@components/web/src/templates/Modals/CommentModal/CommentModal';

interface IProps {
  isCommentsDataLoading: boolean;
  isNewCommentsDataFetching: boolean;
  setIsNewCommentsDataFetching: Dispatch<SetStateAction<boolean>>;
  handleSubmitComment?: (comment: string) => void;
  commentFromList: IComment;
  isCommentInList?: boolean;
  isFromSharedLink?: boolean;
}

const CommentForProductPage: FC<IProps> = ({
  isCommentsDataLoading,
  handleSubmitComment,
  isNewCommentsDataFetching,
  setIsNewCommentsDataFetching,
  commentFromList,
  isCommentInList,
  isFromSharedLink,
}) => {
  const { userFirstName } = useUser();
  const [isCommentModalOpen, { open: openModal, close: closeModal }] = useDisclosure();

  const { btnTextNoComment, btnTextWithComment, myPersonalNote, userNote } = localeCommon.comment;

  return (
    <>
      <S.CommentContainer
        isFullWidth
        direction="column"
        gap={8}
        padding="12px"
        onClick={() => (!isFromSharedLink ? openModal() : null)}
      >
        <S.TitleWrapper>
          <Image alt="Comment icon" height="20px" src={isCommentInList ? noteIconFill : noteIcon} width="25px" />
          {isFromSharedLink ? (
            <Text
              color={STATIC_COLORS.base.black}
              fontFamily="Fraunces"
              size="subtitle2"
              text={userNote}
              weight="semibold"
              localeOptions={{
                userFirstName,
              }}
            />
          ) : (
            <Text
              color={STATIC_COLORS.base.black}
              fontFamily="Fraunces"
              size="subtitle2"
              text={myPersonalNote}
              weight="semibold"
            />
          )}
        </S.TitleWrapper>
        {isCommentInList && (
          <Text color={STATIC_COLORS.base.black} linesLimit={4} size="body2" text={commentFromList?.comment} />
        )}
        {!isFromSharedLink && (
          <Flexbox isFullWidth justify="flex-end">
            <Text
              color={STATIC_COLORS.teal[600]}
              size="body2"
              text={isCommentInList ? btnTextWithComment : btnTextNoComment}
              textDecoration="underline"
              weight="semibold"
              onClick={openModal}
            />
          </Flexbox>
        )}
      </S.CommentContainer>

      {isCommentModalOpen && (
        <CommentModal
          commentFromList={commentFromList}
          handleSubmitComment={handleSubmitComment}
          isCommentModalOpen={isCommentModalOpen}
          isCommentsDataLoading={isCommentsDataLoading}
          isNewCommentsDataFetching={isNewCommentsDataFetching}
          setIsNewCommentsDataFetching={setIsNewCommentsDataFetching}
          onClose={closeModal}
        />
      )}
    </>
  );
};

export default CommentForProductPage;
