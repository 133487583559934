import { FC } from 'react';

import { TParsedProductInstance, TProductCategory } from '@lib/core/products/types';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { PRODUCT_CATEGORY_COFFEE, VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import ChefRecommendsCard, { IRecipe } from '@components/web/src/organisms/Cards/ChefRecommendsCard/ChefRecommendsCard';
import EssenceCard, { IEssenceProps } from '@components/web/src/organisms/Cards/EssenceCard/EssenceCard';
import IdentityCard from '@components/web/src/organisms/Cards/IdentityCard/IdentityCard';
import PreparationCard, { IPreparationItem } from '@components/web/src/organisms/Cards/PreparationCard/PreparationCard';
import ReadMoreCard, { IReadMoreCardProps } from '@components/web/src/organisms/Cards/ReadMoreCard/ReadMoreCard';
import ProductDetails from '@components/web/src/organisms/ProductDetails/Web/ProductDetails';
import SwiperHOC from '@components/web/src/organisms/Swiper/SwiperHOC';
import FindProductPopup from '@components/web/src/shared/FindProductPopup/FindProductPopup';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';
import * as S from '@components/web/src/templates/ProductPage/Web/styles';

interface IProps {
  parsedProductInstanceData: TParsedProductInstance;
  productCategory: TProductCategory;
  perfectForData: string;
  recipesData: IRecipe[];
  isRecipesDataLoading?: boolean;
  essencesData: IEssenceProps[];
  preparationsData: IPreparationItem[];
  sustainabilityData: IReadMoreCardProps[];
  isShowPromoLabel: boolean;
  shouldHideWishlist: boolean;
  shouldHideComment: boolean;
  shouldHideFeedback: boolean;
  shouldHideShare: boolean;
  isProductPDFDownload?: boolean;
  isUserHasAnyCharacter?: boolean;
  discoveryQuiz: IDiscoveryQuiz;
  isFromSharedLink?: boolean;
  navigateToQuizSelectionPage: () => void;
  onBackButtonClick: () => void;
  navigateToCatalog: () => void;
}

const ProductPage: FC<IProps> = ({
  parsedProductInstanceData,
  productCategory,
  perfectForData,
  recipesData,
  essencesData,
  preparationsData,
  sustainabilityData,
  isShowPromoLabel,
  isProductPDFDownload,
  shouldHideComment,
  shouldHideFeedback,
  shouldHideWishlist,
  shouldHideShare,
  discoveryQuiz,
  isUserHasAnyCharacter,
  navigateToQuizSelectionPage,
  onBackButtonClick,
  navigateToCatalog,
  isRecipesDataLoading,
  isFromSharedLink,
}) => {
  const { publishedTerms } = LocaleUtils;

  const {
    isFindProductButtonEnable,
    productLocationDescription,
    productLocationMap,
    productEcommerceId,
    identitiesForWeb,
    isProductInstanceInWishlist,
    isEnableLocationMapAddon,
    isEnableVusionAddon,
    productProducerUrl,
  } = parsedProductInstanceData || {};

  const { productIdentities, identityWarningText } = identitiesForWeb;
  const {
    productPage: {
      perfectForTitle,
      preparationsTitle,
      identityCardTitle,
      identityCardSubtitle,
      seeSimilarBtn,
      chefRecommendsTitle,
      chefRecommendsSubtitle,
      essenceCardSubtitle,
      essenceCardTitle,
      sustainabilityTitle,
      sustainabilitySubtitle,
    },
    productDetails: { findItOnShelf, redirectProducerBtn },
    productCategoriesPlural,
    productCategories,
  } = localeCommon;

  const productCategoriesText = LocaleUtils.publishedTerms[productCategories[productCategory].id];
  const productCategoryPluralText = publishedTerms[productCategoriesPlural[productCategory].id];

  return (
    <S.WebProductPageContainer>
      {!isFromSharedLink && <S.StyledBackButton handleClick={onBackButtonClick} />}
      <ProductDetails
        discoveryQuiz={discoveryQuiz}
        isFromSharedLink={isFromSharedLink}
        isProductPDFDownload={isProductPDFDownload}
        isRecipesDataLoading={isRecipesDataLoading}
        isShowPromoLabel={isShowPromoLabel}
        isUserHasAnyCharacter={isUserHasAnyCharacter}
        navigateToQuizSelectionPage={navigateToQuizSelectionPage}
        parsedProductInstanceData={parsedProductInstanceData}
        recipesData={recipesData}
        shouldHideComment={shouldHideComment}
        shouldHideFeedback={shouldHideFeedback}
        shouldHideShare={shouldHideShare}
        shouldHideWishlist={shouldHideWishlist}
      />
      <S.ContentWrapper direction="column-reverse" gap={32} padding="32px 16px 48px">
        <Flexbox isFullWidth direction="column" gap={32}>
          {perfectForData && (
            <Flexbox direction="column" gap={0}>
              <S.Title
                color={STATIC_COLORS.base.black}
                fontFamily="Fraunces"
                size="h5"
                text={perfectForTitle}
                weight="semibold"
              />
              <S.PerfectForWrapper>
                <Text color={STATIC_COLORS.base.black} size="subtitle2" text={perfectForData} />
              </S.PerfectForWrapper>
            </Flexbox>
          )}
          {productCategory === PRODUCT_CATEGORY_COFFEE && (
            <S.PreparationsWrapper>
              <S.Title
                color={STATIC_COLORS.base.black}
                fontFamily="Fraunces"
                size="h5"
                text={preparationsTitle}
                weight="semibold"
              />
              <SwiperHOC spaceBetween={13}>
                {preparationsData?.map((preparationsItem, idx) => <PreparationCard key={idx} {...preparationsItem} />)}
              </SwiperHOC>
            </S.PreparationsWrapper>
          )}
          <Flexbox direction="column" gap={0}>
            <S.Title
              color={STATIC_COLORS.base.black}
              fontFamily="Fraunces"
              size="h5"
              text={identityCardTitle}
              weight="semibold"
            />
            <S.Subtitle
              color={STATIC_COLORS.base.black}
              size="body2"
              text={identityCardSubtitle}
              localeOptions={{
                productCategoriesText,
                productCategory,
              }}
            />
            <IdentityCard identityWarningText={identityWarningText} productIdentities={productIdentities} />
          </Flexbox>
          {isFindProductButtonEnable && (
            <FindProductPopup
              isEnableLocationMapAddon={isEnableLocationMapAddon}
              isEnableVusionAddon={isEnableVusionAddon}
              productEcommerceId={productEcommerceId}
              productLocationDescription={productLocationDescription}
              productLocationMap={productLocationMap}
            >
              <Button
                size="sm"
                text={findItOnShelf}
                type="button"
                variant={VH_VARIANTS.PRIMARY}
                onClick={() =>
                  MixpanelTracker.events.findMe(
                    parsedProductInstanceData,
                    isProductInstanceInWishlist,
                    null,
                    MP_POSITION_CONTEXT.PRODUCT_PAGE,
                  )
                }
              />
            </FindProductPopup>
          )}
          {!isFromSharedLink && (
            <Button
              iconVariant="stack"
              localeOptions={{ productCategory, productCategoryPluralText }}
              size="sm"
              text={seeSimilarBtn}
              variant={VH_VARIANTS.LIGHT}
              handleClick={() => {
                MixpanelTracker.events.seeSimilarProductsClick(parsedProductInstanceData);
                navigateToCatalog();
              }}
            />
          )}
        </Flexbox>
        <Flexbox isFullWidth direction="column" gap={32}>
          {!!recipesData.length && (
            <Flexbox direction="column" gap={0}>
              <S.Title
                color={STATIC_COLORS.base.black}
                fontFamily="Fraunces"
                size="h5"
                text={chefRecommendsTitle}
                weight="semibold"
              />
              <S.Subtitle
                color={STATIC_COLORS.base.black}
                size="body2"
                text={chefRecommendsSubtitle}
                localeOptions={{
                  productCategoriesText,
                  productCategory,
                }}
              />
              <SwiperHOC spaceBetween={13}>
                {recipesData.map((recipeItem, idx) => (
                  <ChefRecommendsCard key={idx} {...recipeItem} />
                ))}
              </SwiperHOC>
            </Flexbox>
          )}
          {!!essencesData.length && (
            <Flexbox direction="column" gap={0}>
              <S.Title
                color={STATIC_COLORS.base.black}
                fontFamily="Fraunces"
                size="h5"
                text={essenceCardTitle}
                weight="semibold"
                localeOptions={{
                  productCategoriesText,
                  productCategory,
                }}
              />
              <S.Subtitle color={STATIC_COLORS.base.black} size="body2" text={essenceCardSubtitle} />
              <SwiperHOC spaceBetween={13}>
                {essencesData.map(essenceObj => (
                  <EssenceCard key={essenceObj.essence.identifier} productCategory={productCategory} {...essenceObj} />
                ))}
              </SwiperHOC>
            </Flexbox>
          )}
          {!!sustainabilityData.length && (
            <Flexbox direction="column" gap={0}>
              <S.Title
                color={STATIC_COLORS.base.black}
                fontFamily="Fraunces"
                size="h5"
                text={sustainabilityTitle}
                weight="semibold"
              />
              <S.Subtitle
                color={STATIC_COLORS.base.black}
                size="body2"
                text={sustainabilitySubtitle}
                localeOptions={{
                  productCategoriesText,
                  productCategory,
                }}
              />
              <SwiperHOC spaceBetween={13}>
                {sustainabilityData.map((sustainabilityItem, idx) => {
                  return (
                    <ReadMoreCard
                      key={idx}
                      cardTitle={sustainabilityItem?.cardTitle}
                      description={sustainabilityItem?.description}
                      minimumCharacters={120}
                    />
                  );
                })}
              </SwiperHOC>
            </Flexbox>
          )}
        </Flexbox>
      </S.ContentWrapper>
      {productProducerUrl && isFromSharedLink && (
        <S.SharedLinkOpenFooterContainer>
          <a {...(productProducerUrl ? { href: productProducerUrl, target: '_blank' } : {})}>
            <Button
              disabled={!productProducerUrl}
              handleClick={() => MixpanelTracker.events.contactTheProductProducer(parsedProductInstanceData)}
              size="lg"
              text={redirectProducerBtn}
              variant={VH_VARIANTS.PRIMARY}
            />
          </a>
        </S.SharedLinkOpenFooterContainer>
      )}
    </S.WebProductPageContainer>
  );
};

export default ProductPage;
