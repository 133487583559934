import { useState } from 'react';
import { IntlShape, injectIntl } from 'react-intl';

import { useCharacters } from '@lib/core/characters/hooks';
import { isCharacterByIdentifiers } from '@lib/core/characters/utils/filters';
import { TParsedProductInstance } from '@lib/core/products/types';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useApp } from '@lib/core/service/hooks';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { ILocaleType } from '@lib/tools/locale/views/LocaleFragment';
import { fileNameGenerator } from '@lib/tools/shared/helpers/utils';

import { IRecipe } from '@components/web/src/organisms/Cards/ChefRecommendsCard/ChefRecommendsCard';
import ProductDetailsPdfPage, {
  TProductDetailsPdfProps,
} from '@components/web/src/templates/ProductDetailsPdfPage/ProductDetailsPdfPage';

type IProps = {
  parsedProductInstanceData: TParsedProductInstance;
  isRecipesDataLoading: boolean;
  recipesData: IRecipe[];
  intl: IntlShape;
};

const ProductDetailsPdfContainer = ({
  isRecipesDataLoading,
  recipesData,
  parsedProductInstanceData,
  intl: { formatMessage },
}: IProps) => {
  const [qrDataURL, setQrDataURL] = useState<string>('');

  const { route } = useApp();

  const { origin } = window.location;

  const urlToGenerateQR = `${origin}${route}`;

  const { productCharacterId, productTags, productPerfectFor, productName, identitiesForWeb } =
    parsedProductInstanceData;

  const { characters, isCharactersLoading } = useCharacters();
  const { retailerName, retailerLogo, retailerTags, isRetailerLoading } = useRetailer();

  const productCharacterData = characters.find(character => isCharacterByIdentifiers(character, [productCharacterId]));

  const { characterRelatedEssencesSorted } = productCharacterData;

  if (isRecipesDataLoading || isRetailerLoading || isCharactersLoading) {
    return null;
  }

  if (parsedProductInstanceData) {
    const { recommendedTitle, scanDescription, poweredBy, scanHere, perfectSituation } = localeCommon.productDetailsPDF;
    const { identityCardTitle } = localeCommon.productPage;

    const productIdentitiesList = identitiesForWeb?.productIdentities?.map(productIdentity => ({
      description: productIdentity.description,
      title: formatMessage(productIdentity.title as any),
    }));

    const parsedRecipesData = recipesData?.map(recipe => ({
      ...recipe,
      subtitle: formatMessage(recipe.subtitle as ILocaleType),
      title: recipe.title as string,
    }));

    const progressBarData = characterRelatedEssencesSorted?.map(essenceObject => ({
      progress: essenceObject.score * 20,
      title: essenceObject.essence.name,
    }));

    const handleQRDataURLChange = (dataURL: string) => {
      setQrDataURL(dataURL);
    };

    const handleDownloadClick = () => {
      MixpanelTracker.events.createProductPDF(parsedProductInstanceData);
    };

    const { publishedTerms } = LocaleUtils;

    const recommendedTitleText = publishedTerms[recommendedTitle.id];
    const scanDescriptionText = publishedTerms[scanDescription.id];
    const poweredByText = publishedTerms[poweredBy.id];
    const scanHereText = publishedTerms[scanHere.id];
    const perfectSituationText = publishedTerms[perfectSituation.id];
    const uniqueFeatureText = publishedTerms[identityCardTitle.id];

    const productDetails: TProductDetailsPdfProps = {
      parsedProductInstanceData,
      productIdentities: productIdentitiesList,
      productTags,
      progressBarData,
      qrDataURL,
      recommendationList: parsedRecipesData,
      retailerLogo: retailerLogo || retailerTags?.homeImage,
      retailerName,
      staticTexts: {
        perfectSituationText,
        poweredByText,
        recommendedTitleText,
        scanDescriptionText,
        scanHereText,
        uniqueFeatureText,
        whenToDrink: productPerfectFor,
      },
    };

    return (
      <ProductDetailsPdfPage
        fileName={fileNameGenerator(productName)}
        handleDownloadClick={handleDownloadClick}
        handleQRDataURLChange={handleQRDataURLChange}
        productDetails={productDetails}
        urlToGenerateQR={urlToGenerateQR}
      />
    );
  }

  return null;
};

export default injectIntl(ProductDetailsPdfContainer);
