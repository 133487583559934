import { FC, useState } from 'react';

import { TQuizAnswerData } from '@lib/core/quizzes/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import Question from '@components/web/src/atoms/Quiz/Question/Question';
import AnswerItem from '@components/web/src/atoms/TestAnswer/TestAnswerItem/TestAnswer';
import { KioskCirclesBackground } from '@components/web/src/kiosk/KioskCirclesBackground/KioskCirclesBackground';
import ProgressBar from '@components/web/src/organisms/ProgressBar/ProgressBar';
import * as S from '@components/web/src/templates/TestFlowsPages/QuizPage/styles';

interface Props {
  answers: TQuizAnswerData[];
  question: ILocaleText;
  progressPercent: number;
  firstQuestion: boolean;
  withoutProgressBar?: boolean;
  isApplicationKiosk: boolean;
  isDesignSetVinhoodExperience: boolean;
  isDevToolsEnabled: boolean;
  isUserQuizLoading?: boolean;
  goBack: () => void;
  onAnswerSelectHandler: (id: string) => void;
  customColors: {
    customQuizBackgroundColor: string;
    customTextColor: string;
    customSecondaryColor: string;
  };
}

const QuizPage: FC<Props> = ({
  answers,
  withoutProgressBar,
  question,
  progressPercent,
  isApplicationKiosk,
  isDesignSetVinhoodExperience,
  isDevToolsEnabled,
  isUserQuizLoading,
  goBack,
  onAnswerSelectHandler,
  customColors,
}) => {
  const [isDisableQuiz, setIsDisableQuiz] = useState(false);

  const { commonMessages } = localeCommon;
  const { customQuizBackgroundColor, customTextColor, customSecondaryColor } = customColors;

  const customButtonArrowColor =
    isDesignSetVinhoodExperience && customSecondaryColor ? customSecondaryColor : '#4091A0';

  return (
    <S.QuizPageContainer
      isFullWidth
      $background={customQuizBackgroundColor}
      $withoutProgressBar={withoutProgressBar}
      align="center"
      direction="column"
      gap={24}
      justify="center"
      padding="16px"
    >
      {isApplicationKiosk && <KioskCirclesBackground />}
      {!withoutProgressBar && (
        <S.ProgressBarWrapper>
          <ProgressBar progressPercentage={progressPercent} />
        </S.ProgressBarWrapper>
      )}
      <Question customTextColor={customTextColor} isApplicationKiosk={isApplicationKiosk} title={question} />
      <S.AnswersContainer align="center" justify="center">
        {answers?.map(answer => {
          const answerId = Object.keys(answer)[0];
          return (
            <AnswerItem
              key={answerId}
              customTextColor={customTextColor}
              id={answerId}
              image={answer[answerId].image}
              isApplicationKiosk={isApplicationKiosk}
              isDevToolsEnabled={isDevToolsEnabled}
              isDisabled={isDisableQuiz}
              isSelected={false}
              isUserQuizLoading={isUserQuizLoading}
              setIsDisableQuiz={setIsDisableQuiz}
              text={answer[answerId].text}
              onSelect={onAnswerSelectHandler}
            />
          );
        })}
      </S.AnswersContainer>
      {goBack && (
        <S.GoBackButton
          customArrowColor={customButtonArrowColor}
          handleClick={goBack}
          style={{ color: customTextColor }}
          text={commonMessages.goBackBtn}
          variant="long"
        />
      )}
    </S.QuizPageContainer>
  );
};

export default QuizPage;
