import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TProductCategory } from '@lib/core/products/types';
import { selectRetailerSlug } from '@lib/core/retailers/selectors/retailer';
import { IRetailerDetail } from '@lib/core/retailers/types/retailerDetail';
import { REQUEST_METHODS } from '@lib/core/service/consts';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import { requestWithKeysAsOptions } from '@lib/core/service/requests/request';
import { prependBasename } from '@lib/core/service/utils';
import { history } from '@lib/core/service/utils/Navigator';
import { selectUserBestMatchCharacterByProductCategory } from '@lib/core/users/selectors/characters';
import { selectFidelityCardId } from '@lib/core/users/selectors/fidelityCard';
import { actionGetUserCharacters, actionResetUserCharactersSlice } from '@lib/core/users/slices/characters';
import { fidelityCardApiUrlCreator } from '@lib/core/users/slices/urls';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { FidelityModalsTypes } from '@lib/tools/shared/helpers/consts';
import { PAGES } from '@lib/tools/views/urls';

export interface IFidelityCardResponse {
  fidelity_card: {
    retailer?: IRetailerDetail;
    card_identifier: string;
  };
}
export interface IFidelityCardState {
  isScanningLoading: boolean;
  fidelityCardData: IFidelityCardResponse | Record<string, never>;
  activeFidelityModalCode: FidelityModalsTypes | undefined;
}
export const initialState: IFidelityCardState = {
  activeFidelityModalCode: undefined,
  fidelityCardData: {},
  isScanningLoading: false,
};

const createFidelityCard = async ({
  fidelityCardCode = '',
  retailerSlug,
}: {
  retailerSlug: string;
  fidelityCardCode: string;
}) =>
  await requestWithKeysAsOptions({
    apiPath: fidelityCardApiUrlCreator({}),
    body: {
      fidelity_card: {
        card_identifier: `${fidelityCardCode}`,
        retailer: retailerSlug,
      },
    },
    config: {
      method: REQUEST_METHODS.POST,
    },
    withErrorReturn: true,
  });

export const handleFidelityCodeThunk = createTypedAsyncThunk(
  'handleFidelityCodeThunk',
  async (
    { fidelityCardCode, productCategory }: { fidelityCardCode: string; productCategory: TProductCategory },
    { getState, dispatch },
  ) => {
    const state = getState();
    const fidelityCardId = selectFidelityCardId(state);
    const isScannedSameCard = fidelityCardId && fidelityCardCode === fidelityCardId;
    const isScannedNewCardOverAnother = fidelityCardId && fidelityCardCode !== fidelityCardId;
    const retailerSlug = selectRetailerSlug(state);

    if (isScannedSameCard) {
      return Promise.reject();
    }

    if (isScannedNewCardOverAnother) {
      dispatch(actionResetUserCharactersSlice());
    }

    let fidelityCardData: IFidelityCardResponse = await requestWithKeysAsOptions({
      apiPath: fidelityCardApiUrlCreator({ fidelityCardCode }),
      withErrorReturn: true,
    });

    const isFidelityCardNotExists = !fidelityCardData?.fidelity_card;

    if (isFidelityCardNotExists) {
      fidelityCardData = await createFidelityCard({ fidelityCardCode, retailerSlug });
      history.push(prependBasename(PAGES.vinhood.home));
    }
    if (!fidelityCardData?.fidelity_card?.card_identifier) {
      MixpanelTracker.events.scanFailed(fidelityCardCode);
      history.push(prependBasename(PAGES.vinhood.home));
      return { activeFidelityModalCode: FidelityModalsTypes.NotFoundCard, fidelityCardData: {} };
    }

    if (!isFidelityCardNotExists) {
      history.push(prependBasename(PAGES.vinhood.home));
    }

    MixpanelTracker.events.scanFidelityCard(fidelityCardData.fidelity_card.card_identifier, retailerSlug);
    // Return the active fidelity modal code and fidelity card data

    await dispatch(actionGetUserCharacters());
    const userCharacter = selectUserBestMatchCharacterByProductCategory(state)?.[productCategory];
    return {
      activeFidelityModalCode: userCharacter
        ? FidelityModalsTypes.SuccessWithCharacter
        : FidelityModalsTypes.SuccessWithoutCharacter,
      fidelityCardData,
    };
  },
);
export const fidelityCardSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(handleFidelityCodeThunk.pending, state => {
      state.isScanningLoading = true;
    });
    builder.addCase(handleFidelityCodeThunk.fulfilled, (state, action) => {
      const { payload: { activeFidelityModalCode, fidelityCardData = {} } = {} } = action;
      state.isScanningLoading = false;
      state.fidelityCardData = { ...fidelityCardData };
      state.activeFidelityModalCode = activeFidelityModalCode;
    });
    builder.addCase(handleFidelityCodeThunk.rejected, state => {
      state.isScanningLoading = false;
    });
  },
  initialState,
  name: 'fidelityCard',
  reducers: {
    actionShowFailedScanModal(state) {
      state.activeFidelityModalCode = FidelityModalsTypes.NotFoundCard;
    },
    closeFidelityCardModal(state) {
      state.activeFidelityModalCode = undefined;
    },
    detachFidelityCard(_state, action: PayloadAction<{ displayMessage?: boolean }>) {
      const {
        payload: { displayMessage },
      } = action;
      return {
        ...initialState,
        activeFidelityModalCode: displayMessage ? FidelityModalsTypes.CardDisconnected : undefined,
      };
    },
    firstTimeUserSimulationScan(state, { payload }: PayloadAction<string>) {
      state.isScanningLoading = false;
      state.fidelityCardData = {
        fidelity_card: {
          card_identifier: payload,
        },
      };
      state.activeFidelityModalCode = FidelityModalsTypes.SuccessWithoutCharacter;
    },
  },
});

export default fidelityCardSlice.reducer;

export const { detachFidelityCard, closeFidelityCardModal, firstTimeUserSimulationScan, actionShowFailedScanModal } =
  fidelityCardSlice.actions;
