import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';
import { TProductCategory } from '@lib/core/products/types';
import { localeApp } from '@lib/tools/locale/source/web/app';
import {
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';

import { ICharacterInfoCardProps } from '@components/web/src/organisms/Cards/CharacterInfo/CharacterInfo';

interface IProps {
  character: IParsedCharacter;
  productCategory?: TProductCategory;
  locale?: string;
}

export const parseCharacterInfoCardProps = ({
  character,
  productCategory,
  locale,
}: IProps): ICharacterInfoCardProps[] => {
  const { createCharacterTasteDataText } = localeApp;

  const {
    characterAttributes,
    characterFoodPairing,
    // preparation_classic: preparationClassic,
    // preparation_specialty: preparationSpecialty,
  } = character;
  if (productCategory === PRODUCT_CATEGORY_COFFEE && locale && character) {
    // return {
    //   characterInfoCardProps: [
    // {
    //   icon: preparationClassic?.image,
    //   tasteText: `${preparationClassic?.character_name} - ${preparationClassic?.technical_caption}`,
    //   tasteTitle: createCharacterTasteDataText.classicCoffeeText,
    // },
    // {
    //   icon: preparationSpecialty?.image,
    //   tasteText: `${preparationSpecialty?.character_name} - ${preparationSpecialty?.technical_caption}`,
    //   tasteTitle: createCharacterTasteDataText.specialCoffeeText,
    // },
    //   ],
    // };
  }
  if (productCategory === PRODUCT_CATEGORY_WINE) {
    return [
      {
        icon: characterAttributes?.ideal_glass_img,
        tasteText: `**${characterAttributes?.[`ideal_glass_${locale}`]?.trim() || ''}**`,
        tasteTitle: createCharacterTasteDataText.wineGlassText,
      },
      {
        icon: characterAttributes?.ideal_temperature_img,
        tasteText: `**${characterAttributes?.ideal_temperature_min_celsius}° - ${characterAttributes?.ideal_temperature_max_celsius}°**`,
        tasteTitle: createCharacterTasteDataText.temperatureText,
      },
      {
        icon: characterFoodPairing?.image,
        tasteText: `**${characterFoodPairing?.title || ''}**`,
        tasteTitle: createCharacterTasteDataText.foodPariringText,
      },
    ];
  }
  if (productCategory === PRODUCT_CATEGORY_BEER) {
    return [
      {
        icon: characterAttributes?.ideal_glass_img,
        tasteText: `**${characterAttributes?.[`ideal_glass_${locale}`]?.trim() || ''}**`,
        tasteTitle: createCharacterTasteDataText.glassText,
      },
      {
        icon: characterAttributes?.ideal_temperature_img,
        tasteText: `**${characterAttributes?.ideal_temperature_min_celsius}° - ${characterAttributes?.ideal_temperature_max_celsius}°**`,
        tasteTitle: createCharacterTasteDataText.temperatureText,
      },
      {
        icon: characterFoodPairing?.image,
        tasteText: `**${characterFoodPairing?.title || ''}**`,
        tasteTitle: createCharacterTasteDataText.foodPariringText,
      },
    ];
  }
  return [];
};
