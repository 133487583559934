import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useApp } from '@lib/core/service/hooks';
import { prependBasename } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { PAGES } from '@lib/tools/views/urls';

import CharacterInfo from '@components/web/src/organisms/CharacterInfo/CharacterInfo';
import { CharacterDetailsSkeleton } from '@components/web/src/organisms/Skeleton/CharacterDetailsSkeleton/CharacterDetailsSkeleton';
import * as S from '@components/web/src/templates/Catalogs/Web/ProductCatalogPage/styles';

const CatalogContainerHOC = ({ children }) => {
  const navigate = useNavigate();

  const [isScrolled, setIsScrolled] = useState(false);
  const [characterInfoHeight, setCharacterInfoHeight] = useState(0);
  const touchStartYRef = useRef(0);
  const characterInfoRef = useRef<HTMLDivElement>(null);

  const { locale, productCategory } = useApp();
  const { isUserCharactersLoading, userFirstName, userBestMatchCharacterForCurrentServiceProductCategory } = useUser();

  const isNoCharacterVariant = !isUserCharactersLoading && !userBestMatchCharacterForCurrentServiceProductCategory;

  useEffect(() => {
    const container = document.getElementById('root-scroll-element');

    const handleWheel = event => {
      if (event.deltaY < 0) {
        setIsScrolled(true);
      }
    };

    const handleTouchMove = event => {
      const touchCurrentY = event.touches[0].clientY;
      if (touchCurrentY > touchStartYRef.current) {
        setIsScrolled(true);
      }
    };
    container?.addEventListener('wheel', handleWheel);
    container?.addEventListener('touchmove', handleTouchMove);

    return () => {
      container?.removeEventListener('wheel', handleWheel);
      container?.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  useEffect(() => {
    if (characterInfoRef.current) {
      setCharacterInfoHeight(characterInfoRef.current.clientHeight);
    }
  }, [isScrolled]);

  const navigateToCharacterDetailsPage = () => navigate(prependBasename(PAGES.vinhood.characterDetails));

  const navigateToQuizPage = () => {
    MixpanelTracker.events.startTasteTestClick();
    navigate(prependBasename(PAGES.vinhood.ageAndGender));
  };

  return (
    <S.CatalogContainerHoc>
      {isUserCharactersLoading ? (
        <CharacterDetailsSkeleton productCategory={productCategory} />
      ) : (
        <S.CharacterInfoWrapper $height={isScrolled || isNoCharacterVariant ? characterInfoHeight : 100}>
          <CharacterInfo
            ref={characterInfoRef}
            character={userBestMatchCharacterForCurrentServiceProductCategory}
            isNoCharacterVariant={isNoCharacterVariant}
            locale={locale}
            navigateToCharacterDetailsPage={navigateToCharacterDetailsPage}
            navigateToQuizPage={navigateToQuizPage}
            productCategory={productCategory}
            userFirstName={userFirstName}
          />
        </S.CharacterInfoWrapper>
      )}
      {children}
    </S.CatalogContainerHoc>
  );
};

export default CatalogContainerHOC;
