import { Dispatch, FC, Fragment, SetStateAction } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { TParsedProductInstance } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { IThirdPartyNewsletterTexts } from '@lib/core/service/types/interface';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { IHandleProductsRequestParams } from '@lib/tools/shared/helpers/interfaces';

import ProductCard, {
  TProductCardVariants,
} from '@components/web/src/organisms/Cards/ProductCard/OldProductCard/ProductCard';
import * as S from '@components/web/src/organisms/Catalog/ProductList/styles';
import ThirdPartyNewsletter from '@components/web/src/organisms/ThirdPartyNewsletter/ThirdPartyNewsletter';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';

export type ICatalogProps = {
  isProductsRequestLoading: boolean;
  disablePagination?: boolean;
  products: TParsedProductInstance[];
  itemsCount?: number;
  storeType: TRetailerLocationStoreType;
  retailerName?: string;
  isMatchedProductsAvailable?: boolean;
  shouldHideFeedback?: boolean;
  shouldHideWishlist?: boolean;
  shouldHideShare?: boolean;
  discoveryQuiz?: IDiscoveryQuiz;
  variant?: TProductCardVariants;
  isShowThirdPartyAcceptance?: boolean;
  shouldShowDownloadAppCard?: boolean;
  thirdPartyNewsletterTexts: IThirdPartyNewsletterTexts;
  handleThirdPartyAcceptance?: (value: boolean) => void;
  handleProductsRequest: (args: IHandleProductsRequestParams) => void;
  handleAuthFeatureClick?: () => void;
  setIsNewCommentsDataFetching?: Dispatch<SetStateAction<boolean>>;
};

const CatalogueResult: FC<ICatalogProps> = ({
  disablePagination = false,
  products,
  isProductsRequestLoading = false,
  itemsCount,
  storeType,
  retailerName,
  shouldHideFeedback,
  shouldHideWishlist,
  shouldHideShare,
  discoveryQuiz,
  variant,
  isMatchedProductsAvailable,
  shouldShowDownloadAppCard,
  isShowThirdPartyAcceptance,
  thirdPartyNewsletterTexts,
  handleProductsRequest,
  handleThirdPartyAcceptance,
}) => {
  return (
    <S.ProductListContainer>
      <InfiniteScroll
        dataLength={products.length}
        hasMore={!itemsCount || products.length < itemsCount}
        scrollableTarget="root-scroll-element"
        loader={
          isProductsRequestLoading ? (
            <>
              {new Array(6).fill(null).map((_i, index) => (
                <S.ProductCardWrapper key={index} justify="center">
                  <ProductCard isResponsive isLoading={isProductsRequestLoading} storeType={storeType} />
                </S.ProductCardWrapper>
              ))}
            </>
          ) : null
        }
        next={
          (isProductsRequestLoading || disablePagination) && !products.length
            ? null
            : () => handleProductsRequest({ isPagination: true })
        }
      >
        {products.map((parsedProductInstanceData, index) => (
          <Fragment key={index}>
            <S.ProductCardWrapper align="center" direction="column" gap={24} justify="center">
              <ProductCard
                isResponsive
                discoveryQuiz={discoveryQuiz}
                isLoading={isProductsRequestLoading}
                mixpanelIndex={index}
                mixpanelPositionContext={MP_POSITION_CONTEXT.SCROLLABLE_CATALOG}
                parsedProductInstanceData={parsedProductInstanceData}
                shouldHideFeedback={shouldHideFeedback}
                shouldHideShare={shouldHideShare}
                shouldHideWishlist={shouldHideWishlist}
                storeType={storeType}
                variant={variant}
              />
            </S.ProductCardWrapper>
            {index === 0 && isShowThirdPartyAcceptance && !isMatchedProductsAvailable && (
              <ThirdPartyNewsletter
                handleThirdPartyAcceptance={handleThirdPartyAcceptance}
                retailerName={retailerName}
                thirdPartyNewsletterTexts={thirdPartyNewsletterTexts}
                withMarginTop={shouldShowDownloadAppCard}
              />
            )}
          </Fragment>
        ))}
      </InfiniteScroll>
    </S.ProductListContainer>
  );
};

export default CatalogueResult;
