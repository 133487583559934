import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useUserQuiz } from '@lib/core/quizzes/hooks';
import { QUIZ_TYPE_EXPERT, QUIZ_TYPE_MULTI_PRODUCT, QUIZ_TYPE_TASTE } from '@lib/core/quizzes/utils/consts';
import { useApp } from '@lib/core/service/hooks';
import { useThirdParty } from '@lib/core/service/hooks/useThirdParty';
import { setIsTasteIdGuideShown } from '@lib/core/service/slices';
import { prependBasename } from '@lib/core/service/utils';
import { useServiceTerms } from '@lib/core/serviceTerms/hooks/useServiceTerms';
import { IParsedServiceTerm } from '@lib/core/serviceTerms/types';
import { useUser } from '@lib/core/users/hooks';
import {
  actionRegister,
  facebookLoginCallback,
  googleLoginCallback,
  resetAuthError,
} from '@lib/core/users/slices/auth';
import { useAddons } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import { RegistrationFormInputObject } from '@components/web/src/pages/Authentication/AuthFormInterface';
import LoadingSpinner from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';
import { AuthenticationPage } from '@components/web/src/templates/Login&Registration/AuthenticationPage/AuthenticationPage';

export const AuthenticationContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    serviceTermsByCategory: { policy, marketing },
    serviceTermsListUpdateIsLoading,
    updateServiceTerms,
  } = useServiceTerms();
  const { isEnableTastePathAddon, isShowSkipAndGoToProductsAddon } = useAddons();
  const { isRetailerSupportsThirdPartyOption } = useThirdParty();
  const { productCategory } = useApp();
  const { userQuizId, userQuizType } = useUserQuiz();
  const {
    isUserAuthenticated,
    isUserDataLoading,
    authError,
    isAuthDataLoading,
    userBestMatchCharacterForCurrentServiceProductCategory,
    isUserHasAnyCharacter,
  } = useUser();

  const isSocialAuthTesting = searchParams.get('is_social_test') === 'true';

  const [isEmailAuth, setIsEmailAuth] = useState(false);
  const [registerPageServiceTerms, setRegisterPageServiceTerms] = useState({
    marketing,
    policy,
  });

  useEffect(() => {
    if (!isUserAuthenticated) return;

    const shouldConnectCharacterToAccount =
      userQuizId && [QUIZ_TYPE_MULTI_PRODUCT, QUIZ_TYPE_TASTE, QUIZ_TYPE_EXPERT].includes(userQuizType);

    const page =
      !isRetailerSupportsThirdPartyOption && !isEnableTastePathAddon ? PAGES.vinhood.catalog : PAGES.vinhood.thirdParty;

    if (shouldConnectCharacterToAccount) {
      if (isUserHasAnyCharacter) {
        navigate(prependBasename(page));
      }
    } else {
      navigate(prependBasename(page));
    }
  }, [userQuizId, isUserAuthenticated, isUserHasAnyCharacter]);

  useEffect(() => {
    setRegisterPageServiceTerms({ marketing, policy });
  }, [policy, marketing]);

  useEffect(() => {
    return () => {
      dispatch(resetAuthError());
    };
  }, []);

  const handleOnServiceTermToggle = ({ category }: IParsedServiceTerm) =>
    setRegisterPageServiceTerms({
      ...registerPageServiceTerms,
      [category]: {
        ...registerPageServiceTerms[category],
        is_selected: !registerPageServiceTerms[category].is_selected,
      },
    });

  const handleEmailAuthButtonClick = () => {
    setIsEmailAuth(state => !state);
    dispatch(resetAuthError());
  };

  const handleFormSubmit = async (data: RegistrationFormInputObject) => {
    const { email, password, lastName, name } = data;

    await dispatch(
      actionRegister({
        email,
        first_name: name,
        last_name: lastName,
        password,
      }),
    );

    updateServiceTerms(Object.values(registerPageServiceTerms));
    dispatch(setIsTasteIdGuideShown(false));
  };

  const navigateToCatalog = () => navigate(prependBasename(PAGES.vinhood.catalog));

  const navigateToLoginPage = () => navigate(prependBasename(PAGES.vinhood.signIn));

  const isLoading = isUserDataLoading || isAuthDataLoading || isUserAuthenticated || serviceTermsListUpdateIsLoading;

  return isLoading ? (
    <LoadingSpinner variant="spinner" />
  ) : (
    <AuthenticationPage
      authError={authError}
      handleEmailAuthButtonClick={handleEmailAuthButtonClick}
      handleFormSubmit={handleFormSubmit}
      handleOnServiceTermToggle={handleOnServiceTermToggle}
      isEmailAuth={isEmailAuth}
      isEnableTastePathAddon={isEnableTastePathAddon}
      isShowSkipAndGoToProductsAddon={isShowSkipAndGoToProductsAddon}
      isSocialAuthTesting={isSocialAuthTesting}
      navigateToCatalog={navigateToCatalog}
      navigateToLoginPage={navigateToLoginPage}
      productCategory={productCategory}
      registerPageServiceTerms={registerPageServiceTerms}
      responseFacebook={facebookLoginCallback(dispatch, false)}
      responseGoogle={googleLoginCallback(dispatch, true)}
      userCharacterData={userBestMatchCharacterForCurrentServiceProductCategory}
    />
  );
};
