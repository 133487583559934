import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useCharacters } from '@lib/core/characters/hooks';
import { isCharacterByIdentifiers } from '@lib/core/characters/utils/filters';
import { useRecipes } from '@lib/core/productAttributes/hooks';
import { useParsedProducts } from '@lib/core/products/hooks/useParsedProducts';
import { IS_FROM_PRODUCT_DETAILS_PAGE } from '@lib/core/service/consts';
import { prependBasename, promotionsFilter } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import useDiscoveryQuizData from '@lib/tools/discoveryQuiz/hooks';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { setUrlParams } from '@lib/tools/shared/helpers';
import { PRODUCT_CATEGORY_URL_PARAM, PROMOTION_LABEL_SLUG } from '@lib/tools/shared/helpers/consts';
import { useSustainability } from '@lib/tools/sustainability/hooks';
import useGetProductInstance from '@lib/tools/views/hooks/useGetProductInstance';
import { PAGES } from '@lib/tools/views/urls';

import CharacteristicsPage from '@components/web/src/app/ProductDetails/Characteristics/Characteristics';
import TastingPage from '@components/web/src/app/ProductDetails/Tasting/Tasting';
import ProductPage from '@components/web/src/pages/app/Product/AppProductPage';
import LoadingSpinner from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';

const ProductPageContainer: FC = () => {
  const navigate = useNavigate();
  const { state: locationState } = useLocation();

  const { tabCharacteristicsText, tabTastingText } = localeApp.productPage;
  const { isUserHasAnyCharacter, isUserAuthenticated } = useUser();
  const { productInstanceData, isProductInstanceDataLoading } = useGetProductInstance();
  const { parseProduct } = useParsedProducts();
  const parsedProductInstanceData = parseProduct(productInstanceData);
  const {
    productCharacterId,
    productCategory,
    productBadges,
    productPerfectFor,
    productFormatIdentifier,
    identitiesForWeb,
    preparationsData,
  } = parsedProductInstanceData || {};

  const { recipesData, isRecipesDataLoading } = useRecipes(productCharacterId, productCategory);
  const { sustainabilityData, isSustainabilityDataLoading } = useSustainability(
    productCategory,
    productFormatIdentifier,
  );

  const { characters } = useCharacters();
  const { characterRelatedEssencesSorted } = characters.find(character =>
    isCharacterByIdentifiers(character, [productCharacterId]),
  );

  const { discoveryQuiz } = useDiscoveryQuizData();

  const navigateToQuizSelectionPage = () => navigate(prependBasename(PAGES.vinhood.quiz.chooseTaste));

  const tabsData = {
    active: 'characteristics',
    tabs: [
      {
        children: (
          <CharacteristicsPage
            essencesData={characterRelatedEssencesSorted}
            productCategory={productCategory}
            productIdentitiesData={identitiesForWeb}
            tagsData={productBadges}
          />
        ),
        tabCode: 'characteristics',
        tabText: tabCharacteristicsText,
      },
      {
        children: (
          <TastingPage
            perfectForData={productPerfectFor}
            preparationsData={preparationsData}
            productCategory={productCategory}
            recipesData={recipesData}
            sustainabilityData={sustainabilityData}
          />
        ),
        tabCode: 'tasting',
        tabText: tabTastingText,
      },
    ],
  };

  const onBackButtonClick = () => {
    navigate(locationState?.from);
    setUrlParams([
      { key: IS_FROM_PRODUCT_DETAILS_PAGE, value: 'true' },
      { key: PRODUCT_CATEGORY_URL_PARAM, value: productCategory },
    ]);
  };

  const isLoading =
    !productInstanceData ||
    isProductInstanceDataLoading ||
    !recipesData ||
    isRecipesDataLoading ||
    isSustainabilityDataLoading;

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <ProductPage
      shouldDisplayContactProducerBtn
      discoveryQuiz={discoveryQuiz}
      isRecipesDataLoading={isRecipesDataLoading}
      isShowPromoLabel={!!promotionsFilter(productInstanceData?.promotions, [PROMOTION_LABEL_SLUG]).length}
      isUserHasAnyCharacter={isUserHasAnyCharacter}
      navigateToQuizSelectionPage={navigateToQuizSelectionPage}
      parsedProductInstanceData={parsedProductInstanceData}
      recipesData={recipesData}
      shouldHideFeedback={!isUserAuthenticated}
      shouldHideWishlist={!isUserAuthenticated}
      tabsProps={tabsData}
      onBackButtonClick={onBackButtonClick}
    />
  );
};

export default ProductPageContainer;
