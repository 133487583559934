import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectBannersData, selectBannersError, selectIsBannersLoading } from '@lib/core/banners/selectors/banners';
import { EDUTAINMENT_BANNER, TIP_AND_TRICKS_BANNER } from '@lib/tools/shared/helpers/consts';

import { TBannerCard } from '@components/web/src/atoms/Banner/BannerCard';

export const useBanners = () => {
  const bannersData = useSelector(selectBannersData);
  const isBannersLoading = useSelector(selectIsBannersLoading);
  const bannersError = useSelector(selectBannersError);

  const filterBannerPosition = (pagePosition: string): TBannerCard[] =>
    bannersData
      .map(banner => ({
        ...banner,
        // currentPosition indicates the banner click location for Mixpanel event
        currentPosition: banner.positions.find(({ route }) => route === pagePosition),
      }))
      .filter(banner => banner.currentPosition);

  const bannersByPosition = useMemo(
    () => ({
      edutainment: filterBannerPosition(EDUTAINMENT_BANNER),
      tipsAndTricks: filterBannerPosition(TIP_AND_TRICKS_BANNER),
    }),
    [bannersData],
  );

  return {
    bannersData,
    bannersError,
    edutainmentBannersData: bannersByPosition.edutainment,
    isBannersLoading,
    tipsAndTricksBannersData: bannersByPosition.tipsAndTricks,
  };
};
