// eslint-disable-next-line import/no-extraneous-dependencies
import { BEER_ALCOHOL_ESSENCE_ID, HARDCODED_ESSENCE_DATA } from '@app/web/src/helpers/consts';

import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';
import { TProductCategory } from '@lib/core/products/types';
import { localeWidget } from '@lib/tools/locale/source/web/widget';

import { ICharacterAdjectiveItemData } from '@components/web/src/atoms/CharacterAdjective/CharacterAdjective';
import { TSwiperData } from '@components/web/src/organisms/Authentication/AuthBanners/ExtraInfoSwiper/ExtraInfoSwiper';

export const parseCharacterAdjectiveItemsData = (
  userBestMatchCharacterByProductCategory: Record<string, IParsedCharacter>,
  locale: string,
  productCategories: TProductCategory[],
): Record<string, ICharacterAdjectiveItemData[]> => {
  if (!userBestMatchCharacterByProductCategory || !locale) return null;

  const {
    characterAdjectiveAverage,
    characterAdjectiveExcellent,
    characterAdjectiveFair,
    characterAdjectiveGood,
    characterAdjectiveNone,
    characterAdjectivePoor,
  } = localeWidget.testResultPage;

  const CHARACTER_ADJECTIVE_TITLE = {
    0: characterAdjectiveNone,
    1: characterAdjectivePoor,
    2: characterAdjectiveFair,
    3: characterAdjectiveAverage,
    4: characterAdjectiveGood,
    5: characterAdjectiveExcellent,
  };

  const parseData = (character: IParsedCharacter) =>
    character.characterEssences?.map(essenceObj => {
      const {
        essence: { identifier, name },
        score,
      } = essenceObj;
      const isAlcoholEssence = identifier === BEER_ALCOHOL_ESSENCE_ID;
      const title = isAlcoholEssence ? `${name.split(' ')[0]} %` : name;

      return {
        identifier,
        preTitle: CHARACTER_ADJECTIVE_TITLE[score],
        score,
        title,
      };
    });

  return productCategories.reduce((acc, category) => {
    acc[category] = parseData(userBestMatchCharacterByProductCategory[category]);
    return acc;
  }, {});
};

export const parseCharacterAdjectiveModalData = (
  userBestMatchCharacterByProductCategory: Record<string, IParsedCharacter>,
  locale: string,
  productCategories: TProductCategory[],
): Record<string, TSwiperData[]> => {
  if (!userBestMatchCharacterByProductCategory || !locale) return null;

  const {
    characterAdjectiveAverage,
    characterAdjectiveExcellent,
    characterAdjectiveFair,
    characterAdjectiveGood,
    characterAdjectiveNone,
    characterAdjectivePoor,
  } = localeWidget.testResultPage;

  const CHARACTER_ADJECTIVE_TITLE = {
    0: characterAdjectiveNone,
    1: characterAdjectivePoor,
    2: characterAdjectiveFair,
    3: characterAdjectiveAverage,
    4: characterAdjectiveGood,
    5: characterAdjectiveExcellent,
  };

  const parseData = (character: IParsedCharacter, productCategory: TProductCategory) =>
    character.characterEssences?.map(essenceObj => {
      const {
        essence: { identifier, name },
        score,
      } = essenceObj;
      const attribute = HARDCODED_ESSENCE_DATA[identifier].slug[locale];
      const isAlcoholEssence = identifier === BEER_ALCOHOL_ESSENCE_ID;
      const titleName = isAlcoholEssence ? `${name.split(' ')[0]} %` : name;

      return {
        description: character?.characterAttributes[attribute],
        identifier,
        image: HARDCODED_ESSENCE_DATA[identifier].icon[score],
        name: titleName,
        productCategory,
        title: CHARACTER_ADJECTIVE_TITLE[score],
      };
    });

  return productCategories.reduce((acc, category) => {
    acc[category] = parseData(userBestMatchCharacterByProductCategory[category], category);
    return acc;
  }, {});
};

export const sortCharacterAdjectiveModalData = (characterAdjectiveModalData, identifier) => {
  if (!characterAdjectiveModalData) return null;

  const index = characterAdjectiveModalData?.findIndex(item => item.identifier === identifier);

  return index !== -1
    ? [
        characterAdjectiveModalData[index],
        ...characterAdjectiveModalData?.slice(0, index),
        ...characterAdjectiveModalData?.slice(index + 1),
      ]
    : [];
};
