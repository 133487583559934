import { forwardRef } from 'react';

import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';
import { TProductCategory } from '@lib/core/products/types';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { localeCommon } from '@lib/tools/locale/source/web/common';

import { ReactComponent as QuestionIcon } from '@components/web/src/assets/icons/icon_do_test.svg';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/CharacterInfo/styles';

interface IProps {
  locale: string;
  character: IParsedCharacter;
  userFirstName: string;
  productCategory: TProductCategory;
  isNoCharacterVariant: boolean;
  navigateToCharacterDetailsPage: () => void;
  navigateToQuizPage: () => void;
}

const CharacterInfo = forwardRef<HTMLDivElement, IProps>(
  (
    {
      character,
      userFirstName,
      productCategory,
      isNoCharacterVariant,
      navigateToCharacterDetailsPage,
      navigateToQuizPage,
    },
    ref,
  ) => {
    const { characterName, characterImage, characterDescription, characterId } = character;

    const {
      productCategories,
      productCard: { ctaGiveItBtn },
      characterDetailMessages: { userCharacterTitleTextB2B, characterTitleTextB2B, quizVariantTitle, quizVariantBtn },
    } = localeCommon;

    const productCategoryText = productCategories[productCategory];

    const characterTitleText = userFirstName ? userCharacterTitleTextB2B : characterTitleTextB2B;

    return (
      <S.CharacterDetailsContainer ref={ref} $productCategory={productCategory} gap={0}>
        {!isNoCharacterVariant ? (
          <>
            <S.CharacterImage alt="character image" height="155px" src={characterImage} width="85px" />
            <Flexbox isFullWidth direction="column" gap={0} padding="16px 16px 32px 0">
              <Text
                localeOptions={{ productCategory, userFirstName }}
                localeVariables={{ productCategoryText }}
                size="body2"
                text={characterTitleText}
                weight="normal"
              />
              <Text fontFamily="Fraunces" size="h4" text={`#${characterName}`} weight="semibold" />
              <S.CharacterDescriptionWrapper>
                <Text linesLimit={4} size="body1" text={characterDescription} />
              </S.CharacterDescriptionWrapper>
              <S.CtaButton
                type="button"
                onClick={() => {
                  MixpanelTracker.events.characterDescriptionClick({ productCharacterID: characterId });
                  navigateToCharacterDetailsPage();
                }}
              >
                <Text size="body1" text={ctaGiveItBtn} weight="semibold" />
              </S.CtaButton>
            </Flexbox>
          </>
        ) : (
          <>
            <S.ImageWrapper
              $productCategory={productCategory}
              align="center"
              direction="column"
              gap={0}
              justify="center"
            >
              <QuestionIcon />
            </S.ImageWrapper>
            <Flexbox direction="column" gap={4} padding="32px 16px 48px 0">
              <Text
                localeOptions={{ productCategory }}
                localeVariables={{ productCategoryText }}
                size="body2"
                text={quizVariantTitle}
              />
              <S.CtaButton type="button" onClick={navigateToQuizPage}>
                <Text size="body1" text={quizVariantBtn} weight="bold" />
              </S.CtaButton>
            </Flexbox>
          </>
        )}
      </S.CharacterDetailsContainer>
    );
  },
);

export default CharacterInfo;
