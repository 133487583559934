import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useParsedProducts } from '@lib/core/products/hooks/useParsedProducts';
import { TProductCategory } from '@lib/core/products/types';
import { useRetailer, useRetailerLocation } from '@lib/core/retailers/hooks';
import { useApp } from '@lib/core/service/hooks';
import useTastePathData from '@lib/core/service/hooks/useTastePathData';
import { useThirdParty } from '@lib/core/service/hooks/useThirdParty';
import { setHideTastePathTooltipModal, setShowTastePathStartPage } from '@lib/core/service/slices';
import { prependBasename } from '@lib/core/service/utils';
import { useProductFeedback, useUser } from '@lib/core/users/hooks';
import {
  IProductFeedbackDataWithFullProductData,
  TProductFeedback,
  actionGetProductFeedback,
} from '@lib/core/users/slices/productFeedback';
import { isFeedbackByProductCategory } from '@lib/core/users/utils/filters';
import useDiscoveryQuizData from '@lib/tools/discoveryQuiz/hooks';
import { setNextTastePathCategory } from '@lib/tools/tastePathProducts/slices/index';
import { useAddons } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import { TastePathPage } from '@components/web/src/templates/Catalogs/Web/TastePathPage/TastePathPage';

const TastePathContainer: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isEnableAppBanner } = useAddons();

  const { userSessionId, isUserDataLoading, isProfileLoading, isUserAuthenticated } = useUser();
  const { productCategory, shouldHideDownloadAppCard, shouldHideTastePathTooltipModal } = useApp();
  const {
    isTastePathProductsLoading,
    tastePathData,
    currentTastePathProduct,
    nextTastePathCategory,
    shouldChangeTastePathCategory,
    isTastePathDone,
    tasteJourneyData,
    shouldDisplaySkipCategoryBtn,
  } = useTastePathData();

  const { isShowThirdPartyAcceptance, thirdPartyNewsletterTexts, handleThirdPartyAcceptance } = useThirdParty();
  const { isRetailerLocationLoading } = useRetailerLocation();
  const { isRetailerLoading, retailerName } = useRetailer();
  const shouldShowDownloadAppCard = !shouldHideDownloadAppCard && isEnableAppBanner;
  const { feedbackData, lastUpdatedFeedbackId } = useProductFeedback();

  const { discoveryQuiz } = useDiscoveryQuizData();
  const { parseProduct } = useParsedProducts();
  // ToDO optimize flow starts with this function
  const feedbackDataFullProductData = (convertData: TProductFeedback[]): IProductFeedbackDataWithFullProductData[] => {
    const fullFeedbacksData = [];
    convertData.forEach(singleFeedbackData => {
      const { feedback, product } = singleFeedbackData;
      const fullGprlData = parseProduct(product);
      const fullFeedbackData: IProductFeedbackDataWithFullProductData = {
        feedback,
        product: fullGprlData,
      };
      fullFeedbacksData.push(fullFeedbackData);
    });

    return fullFeedbacksData;
  };

  const filteredByCategoryFeedbackData = useMemo(
    () =>
      feedbackDataFullProductData(
        feedbackData.filter(feedback => isFeedbackByProductCategory(feedback, productCategory)),
      ),
    [feedbackData, productCategory],
  );

  useEffect(() => {
    if (shouldChangeTastePathCategory && tastePathData) {
      dispatch(setNextTastePathCategory());
    }
  }, [shouldChangeTastePathCategory, tastePathData]);

  useEffect(() => {
    dispatch(actionGetProductFeedback());
  }, [userSessionId]);

  const navigateToTastePathStartPage = () => {
    dispatch(setShowTastePathStartPage(true));
    navigate(prependBasename(PAGES.vinhood.tastePathStartPart));
  };

  const isLoading =
    isUserDataLoading ||
    isProfileLoading ||
    isRetailerLoading ||
    isRetailerLocationLoading ||
    isTastePathProductsLoading;

  return (
    <TastePathPage
      discoveryQuiz={discoveryQuiz}
      feedbackData={filteredByCategoryFeedbackData}
      handleCloseTooltip={() => dispatch(setHideTastePathTooltipModal())}
      handleSkipTastePathCategory={() => tastePathData && dispatch(setNextTastePathCategory())}
      handleThirdPartyAcceptance={handleThirdPartyAcceptance}
      isShowThirdPartyAcceptance={isShowThirdPartyAcceptance}
      isTastePathDone={isTastePathDone}
      isTastePathProductsLoading={isLoading}
      lastUpdatedFeedbackId={lastUpdatedFeedbackId}
      navigateToTastePathStartPage={navigateToTastePathStartPage}
      nextTastePathCategory={nextTastePathCategory}
      parsedProductInstanceData={parseProduct(currentTastePathProduct)}
      productCategory={productCategory as TProductCategory}
      retailerName={retailerName}
      shouldDisplaySkipCategoryBtn={shouldDisplaySkipCategoryBtn}
      shouldHideFeedback={false}
      shouldHideShare={!isUserAuthenticated}
      shouldHideTastePathTooltipModal={shouldHideTastePathTooltipModal}
      shouldHideWishlist={!isUserAuthenticated}
      shouldShowDownloadAppCard={shouldShowDownloadAppCard}
      tasteJourneyData={tasteJourneyData}
      thirdPartyNewsletterTexts={thirdPartyNewsletterTexts}
    />
  );
};

export default TastePathContainer;
