import { css, styled } from 'styled-components';

import { TProductCategory } from '@lib/core/products/types';

import { STATIC_COLORS, STATIC_GRADIENTS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';

export const PRODUCT_CARD_WIDTH = {
  KIOSK: '328px',
  WEB: '312px',
};

export const ProductCardContainer = styled(Flexbox)<{ $isResponsive: boolean }>`
  position: relative;
  height: 100%;
  width: ${({ $isResponsive }) => ($isResponsive ? `100%` : PRODUCT_CARD_WIDTH.WEB)};
  overflow: hidden;
  cursor: pointer;
  background: ${STATIC_COLORS.base.white};
  border-radius: 8px;
  box-shadow: ${STATIC_SHADOWS.lg};

  ${({ theme, $isResponsive }) =>
    theme?.isKioskRootElementType &&
    css`
      max-width: ${$isResponsive ? '100%' : PRODUCT_CARD_WIDTH.KIOSK};
      width: ${$isResponsive ? '100%' : PRODUCT_CARD_WIDTH};
    `}
`;

export const ProductImageSection = styled(Flexbox)<{
  $isTasteBoostDone: boolean;
  $productCategory: TProductCategory;
}>`
  position: relative;
  height: 210px;
  overflow: hidden;
  background: ${({ $isTasteBoostDone, $productCategory }) =>
    $isTasteBoostDone
      ? STATIC_GRADIENTS.characterGradients[$productCategory]
      : STATIC_GRADIENTS.warmGradientReversed()};
`;

export const PromoIcon = styled.div`
  position: absolute;
  top: 10px;
  left: -48px;
  z-index: 1;
  width: 140px;
  height: 20px;
  padding: 3px 0;
  background-color: #de5242;
  transform: rotate(-45deg);
  text-align: center;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProductTags = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-end;
  height: 100%;
  padding: 16px 0;
  margin-left: 16px;
`;

export const ProductImageCtaContainer = styled(Flexbox)`
  position: absolute;
  right: 0;
  height: 100%;
  padding: 14px 0;
  margin-right: 16px;
`;

export const ProductContentSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 16px;
`;

export const ProducerName = styled(Text)`
  min-height: 18px;

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      min-height: 24px;
      text-overflow: ellipsis;
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}
`;

export const StyledProductName = styled(Text)`
  min-height: 60px;
  max-height: 60px;

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      min-height: 64px;
      max-height: 64px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}
`;

export const ProductRegionText = styled(Text)`
  min-height: 18px;
  max-height: 18px;

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      min-height: 24px;
      max-height: 24px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}
`;

export const ProductCharacterDetailsText = styled(Text)`
  flex-shrink: 0;
`;

export const StyledProductDescription = styled(Text)`
  margin: 8px 0;
  min-height: 32px;
  max-height: 32px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      -webkit-line-clamp: 3;
      min-height: 60px;
      max-height: 60px;
    `}
`;

export const ProductPriceFormatContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
  width: 100%;

  & > div {
    margin-left: auto;
  }

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      height: 30px;
    `};
`;

export const StyledProductFormatText = styled(Text)`
  flex-grow: 1;
  flex-wrap: wrap;
  color: ${STATIC_COLORS.base.black};
`;

export const ProductPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0 8px;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledProductPriceText = styled(Text)`
  white-space: nowrap;
  color: ${STATIC_COLORS.base.black};
`;

export const StyledProductDashedPriceText = styled(Text)`
  color: ${STATIC_COLORS.gray['400']};
  text-decoration-line: line-through;
  white-space: nowrap;
`;

export const ProductCTASection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 4px 4px;
  margin-top: auto;

  .give-it-button {
    height: 38px;
    text-decoration-line: underline;
  }

  .find-product {
    min-height: 52px;
    padding-top: 12px;

    .find-product-button {
      width: 100%;
      height: 40px;
    }
  }
`;

export const StyledBadgesWrapper = styled.div`
  margin: 4px -4px -4px;
`;

export const ProductImage = styled(Image)<{ $isTasteBoostPending?: boolean }>`
  width: 200px;
  height: 200px;
  background: ${({ $isTasteBoostPending }) => ($isTasteBoostPending ? 'transparent' : 'none')};
  filter: ${({ $isTasteBoostPending }) => ($isTasteBoostPending ? 'grayscale(1) opacity(0.2)' : 'none')};
`;

export const ProductImageWrapper = styled(Flexbox)`
  position: relative;
`;

export const Icon = styled(Image)`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-items: center;
  width: 100%;
  height: 100%;
  img {
    width: 32px;
    height: 32px;
    object-fit: contain;
  }
`;
