import { FC } from 'react';
import { RWebShare } from 'react-web-share';

import { prependBasename } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import { UTM_MEDIUM_VINHOOD_WIDGET, UTM_TERM_SHARE_MY_TASTING } from '@lib/tools/dat/consts';
import { PRODUCT_ID_URL_PARAM } from '@lib/tools/shared/helpers/consts';
import { PAGES } from '@lib/tools/views/urls';

import { ReactComponent as ShareDarkIcon } from '@components/web/src/assets/icons/ShareDark.svg';

interface IProps {
  isFromSharedLink?: boolean;
  productInstanceId?: string;
  isFromProductCardSharedLink?: boolean;
}
export const Share: FC<IProps> = ({ isFromSharedLink, productInstanceId, isFromProductCardSharedLink }) => {
  const { userId } = useUser();
  const { origin, href } = window.location;
  const queryParams = {
    utm_medium: UTM_MEDIUM_VINHOOD_WIDGET,
    utm_source: userId ? userId.toString() : '',
    utm_term: UTM_TERM_SHARE_MY_TASTING,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const sharedLink = isFromSharedLink ? href : `${href}&${queryString}`;
  const hrefFromProductCard = `${origin}${prependBasename(PAGES.vinhood.product, { [PRODUCT_ID_URL_PARAM]: productInstanceId })}`;
  const sharedLinkFromProductCard = isFromSharedLink ? hrefFromProductCard : `${hrefFromProductCard}&${queryString}`;

  return (
    <>
      <RWebShare
        data={{
          url: isFromProductCardSharedLink ? sharedLinkFromProductCard : sharedLink,
        }}
      >
        <ShareDarkIcon />
      </RWebShare>
    </>
  );
};
